<template>
  <div style="border-bottom: 1px dashed #cccccc;padding-bottom: 30px;">

    <div class="typeSel">
      <p>试卷类型:</p>
      <div class="radioGroup">
        <el-radio-group v-model="programingLanguage" size="mini">
          <el-radio-button :label="item.value" :key="item.value" v-for="item in programingLanguageType">
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <el-input placeholder="请输入试卷标题，例如：2023年3月" v-model="content" clearable
                style="width: 400px;padding-left: 200px">
        <el-button slot="append" type="primary" style="width: 100px;color: #19d3ea;"
                   icon="el-icon-search" @click="getPaperByName">搜索
        </el-button>
      </el-input>
    </div>
    <div class="typeSel">
      <p>等级:</p>
      <div class="radioGroup">
        <el-radio-group v-model="subject" size="mini">
            <el-radio-button :label="item.value" :key="item.value" v-for="item in subjectType">{{ item.name }}
            </el-radio-button>
        </el-radio-group>
      </div>
      <el-button-group style="padding-left: 505px;" v-if="showPopup==0">
        <el-tooltip class="item" effect="dark" content="缩列图模式" placement="bottom">
          <el-button type="primary" icon="el-icon-menu" @click="clickShowType(0)"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="列表模式" placement="bottom">
          <el-button type="primary" icon="el-icon-tickets" @click="clickShowType(1)"></el-button>
        </el-tooltip>
      </el-button-group>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showPopup: {
      type: Number,
      default: 0
    },
  },
  watch: {
    programingLanguage: {
      handler: function (n, o) {
        let param = {
          type: this.programingLanguage,
          level: this.subject
        }
        this.$emit("changeSel", param)
      },
      deep: true
    },
    subject: {
      handler: function (n, o) {
        let param = {
          type: this.programingLanguage,
          level: this.subject
        }
        this.$emit("changeSel", param)
      },
      deep: true
    },
  },
  data() {
    return {
      content: "",
      programingLanguage: "0",
      programingLanguageType: [{
        name: "Scratch/图形化",
        value: "0"
      },
        {
          name: "C/C++",
          value: "1"
        },
        {
          name: "Python",
          value: "2"
        },
        {
          name: "NOIP",
          value: "3"
        },
        {
          name: "机器人技术",
          value: "4"
        },
        {
          name: "科技素养/计算思维",
          value: "5"
        },
        {
          name: "其他",
          value: "99"
        },
        // {
        // 	name: "无人机技术",
        // 	value: "6"
        // }
      ],
      subject: null,
      subjectType: [{
        name: "全部",
        value: null
      },
        {
          name: "一级",
          value: "1"
        },
        {
          name: "二级",
          value: "2"
        },
        {
          name: "三级",
          value: "3"
        },
        {
          name: "四级",
          value: "4"
        },
        {
          name: "蓝桥杯",
          value: "98"
        },
        {
          name: "NOC/NCT",
          value: "0"
        },
        {
          name: "练习题",
          value: "99"
        }, {
          name: "五级/六级/其他",
          value: "404"
        }
      ]
    }
  },
  methods: {
    clickShowType(value) {
      this.$emit("changeShowType", value)
    },

    getPaperByName() {
      this.loading = true
      let param = {
        name: this.content,
        type: this.programingLanguage,
        level: this.subject
      }
      this.$emit("changeSel", param)
    },
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 400px;

  .top {
    text-align: center;
  }

  .left {
    float: left;
    width: 60px;
  }

  .right {
    float: right;
    width: 60px;
  }

  .bottom {
    clear: both;
    text-align: center;
  }

  .item {
    margin: 4px;
  }

  .left .el-tooltip__popper,
  .right .el-tooltip__popper {
    padding: 8px 10px;
  }
}
::v-deep.el-radio-button:first-child .el-radio-button__inner {
  border-left: none;
  border-radius: 5px
}

::v-deep.el-radio-button:last-child .el-radio-button__inner {
  // border-left: none;
  border-radius: 5px
}

::v-deep .el-radio-button__inner {
  background: none;
  border: none;
  border-radius: 5px
}

.typeSel {
  display: flex;
  margin-top: 10px;

  p {
    font-size: 18px;
    font-weight: 500;
  }

  .radioGroup {
    margin-left: 20px;
  }
}



</style>
