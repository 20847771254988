<template>
	<div class="page" v-loading="loading">
		<div style="margin-top: 30px;">
			<titleSelct @changeSel="changeSel" :showPopup="1"></titleSelct>
			<div v-if="examinationRecordArray.length == 0">
				<el-alert title="暂无数据" type="warning" center show-icon style="margin-top: 50px;">
				</el-alert>
			</div>
			<div v-else>
				<examDetails :examinationRecordArray="examinationRecordArray"></examDetails>
				<!-- 				<div v-if="haveMore" style="text-align: center;">
					<el-divider>加载中 <i class="el-icon-loading"></i></el-divider>
				</div>
				<div v-if="noMore" style="text-align: center;">
					<el-divider>我是有底线的。</el-divider>
				</div> -->
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total" style="margin-top: 20px;text-align: center">
				</el-pagination>
			</div>

		</div>
	</div>
</template>

<script>
	import titleSelct from "@/components/titleSelect.vue"
	import examDetails from "./components/examDetails.vue"
	import {
		examinationRecord
	} from "@/api/index.js"

	export default {
		components: {
			titleSelct,
			examDetails
		},
		data() {
			return {
				loading: false,
				nowType: 0,
				examinationRecordArray: [],
				allInfo: {
					total: 0
				},
				nowType: {},
				currentPage: 1,
				pageSize: 10,
				haveMore: false,
				noMore: false,
				isLoading: true
			};
		},
		mounted() {
			let val = {
				type: 0,
				level: null
			}
			this.nowType = val;
			this.getInfo()
			// let _this = this;
			// window.onscroll = function() {
			// 	//变量scrollTop是滚动条滚动时，距离顶部的距离
			// 	var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			// 	//变量windowHeight是可视区的高度
			// 	var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
			// 	//变量scrollHeight是滚动条的总高度
			// 	var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
			// 	//滚动条到底部的条件
			// 	if (scrollTop + windowHeight == scrollHeight) {
			// 		//到了这个就可以进行业务逻辑加载后台数据了
			// 		// _this.footerText = '我是有底线的';
			// 		if (_this.allInfo.total > _this.examinationRecordArray.length) {
			// 			if (_this.isLoading) {
			// 				_this.isLoading = false
			// 				_this.haveMore = true
			// 				_this.currentPage = _this.currentPage + 1
			// 				setTimeout(() => {
			// 					console.log("2")
			// 					_this.getInfo()
			// 				}, 1000);
			// 				console.log(_this.examinationRecordArray)
			// 				console.log("到了底部");
			// 			}
			// 		} else {
			// 			_this.haveMore = false
			// 			_this.noMore = true
			// 		}
			// 	}
			// }
		},
		watch: {
			nowType: {
				handler: function(n, o) {
					this.resetData();
					this.loading = true
					this.getInfo()
				},
				deep: true
			}
		},
		methods: {
			resetData() {
				this.loading = false,
					this.examinationRecordArray = [],
					this.allInfo = {
						total: 0
					},
					this.currentPage = 1,
					this.pageSize = 10,
					this.haveMore = false,
					this.noMore = false,
					this.isLoading = true
			},
			changeSel(val) {
				this.nowType = val
				// this.getInfo()
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.currentPage = 1;
				this.getInfo()
				// this.
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getInfo()
			},
			getInfo() {
				this.loading = true
				let param = {
					currentPage: this.currentPage,
					pageSize: this.pageSize,
					type: Number(this.nowType.type),
					level: this.nowType.level,
				}

				examinationRecord(param).then(res => {
					// console.log(res)
					if (res.code == "0000") {
						this.allInfo = res.data
						this.examinationRecordArray = res.data.list
						// res.data.list.forEach(item => {
						// 	this.examinationRecordArray.push(item)
						// })
						// this.examinationRecordArray = res.data.list;
						this.loading = false
						// this.haveMore = false
						// this.isLoading = true
						// console.log(this.examinationRecordArray)
					}
				}).catch(() => {
          this.loading = false
					// this.$router.push({
					// 	path: '/'
					// })
				});
			}
		}
	};
</script>

<style scoped>
	.el-divider__text {
		background-color: #f5f5f5;
		color: #808080;
	}
</style>
