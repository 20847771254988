<template>
  <div class="allExams">
    <el-card shadow="hover" class="box-card" v-for="(item,index) in examinationRecordArray" :key="index">
      <div class="box-card-content">
        <div class="box-card-content-block moreLength">
          <p>{{ item.paperName }}</p>
          <p>提交时间：{{ item.createTime }}</p>
          <div style="display: flex;">
            <p>试卷状态:</p>
            <el-tag size="mini" type="success" Plain style="margin-top: 15px;margin-left: 10px;">已审批</el-tag>
          </div>
        </div>
        <div class="box-card-content-block">
          <!--					<p>试卷类型：{{item.paperType}}</p>-->
          <p>试卷类型：常规试卷</p>
          <p>学科：{{ item.examSubject.type | formatType }}</p>
          <p>题目数量：{{ item.questionCount }}</p>
          <p>试卷总分：{{ item.paperScore }}</p>
        </div>
        <div class="box-card-content-block">
          <p>正确题数：{{ item.questionCorrect }}</p>
          <p>最终得分：{{ item.userScore }}</p>
          <p>耗时：{{ item.doTime }}分钟</p>
        </div>
        <div class="box-card-content-block">
          <el-button size="mini" type="primary" style="float: right;margin-top: 20px;margin-left: 10px;" @click="toOnlineClass()">直播解答
          </el-button>
          <el-button size="mini" type="primary" style="float: right;margin-top: 20px;" @click="viewPaper(item)">答案解析
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {examinationRecord} from "@/api/index.js"

export default {
  filters: {
    formatType(type) {
      switch (type) {
        case 0:
          return "Scratch"
          break;
        case 1:
          return "C/C++"
          break;
        case 2:
          return "Python"
          break;
        case 3:
          return "NOIP"
          break;
        default:
          break;
      }
    },
  },
  props: {
    examinationRecordArray: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  methods: {
    viewPaper(item) {
      let jumpRouter =this.$router.resolve({
        path: "/viewTestPaper?id=" + item.id
      })
      window.open(jumpRouter.href, '_blank');
    },
    toOnlineClass(item) {
      this.$router.push({
        path: "/courseSelectionCenter"
      })
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.allExams {
  width: 1280px;
  margin-top: 20px;

  .box-card {
    margin-top: 10px;
    background-color: #FFF;

  }

  .moreLength {
    width: 40% !important;
  }

  .box-card-content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: start;

    .box-card-content-block {
      overflow: hidden;
      width: 20%;
      height: 150px;

      p {
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        //color: #4c596b;
      }

      p:first-child {
      }
    }

    .box-card-content-block:first-child {
      p {
        line-height: 50px;
      }
      margin-left: 20px;
    }

    .box-card-content-block:nth-child(2) {
      p {
        line-height: 35px;
      }
      margin-top: 8px;
    }

    .box-card-content-block:nth-child(3) {
      p {
        line-height: 37px;
      }
      margin-top: 8px;
    }

    .box-card-content-block:nth-child(4) {
      margin-right: 10px;
      .box-card-content-block-tag {
        height: 100px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: auto;

        div {
          margin: 10px 10px 0 0;
        }
      }
    }
  }
}
</style>
